/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'geo': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M22 14c0 5-6 10-6 10s-6-5-6-10c0-1.522.566-2.911 1.5-3.969A5.985 5.985 0 0116 8c1.792 0 3.4.786 4.5 2.031A5.977 5.977 0 0122 14z" _stroke="#19191A" stroke-opacity=".5" stroke-width="1.5"/><path pid="1" d="M18 14a2 2 0 11-4 0 2 2 0 014 0z" _stroke="#19191A" stroke-opacity=".5" stroke-width="1.5"/>'
  }
})
