<template>
	<basket-items :items="items" mini/>
</template>

<script>
import BasketItems from "@/views/Basket/basket-items";
import city from "@/mixins/city";

export default {
	name: "basket-mini",
	components: {BasketItems},
  mixins: [city],
	computed: {
		items() {
			return this.checkBasketItemsAvailability(this.$store.state.session?.basket);
		},
	},
}
</script>

<style lang="scss">

</style>