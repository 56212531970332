<template>
	<div class="basket-form-cost text-center h5">
		<div>
			Ваш заказ:
			<span class="ml-1">{{ itemsCost | nf }} ₽</span>
		</div>
		<div v-if="form.POSTCARD_ID">
			Открытка:
			<span class="ml-1">{{ postcardsCost | nf }} ₽</span>
		</div>
		<template v-if="deliveryCost">
			<div>
				Доставка:
				<span class="ml-1">{{ deliveryCost | nf }} ₽</span>
			</div>
		</template>
    <template v-if="discount">
      <div>
        Ваша выгода:
        <span class="ml-1">{{ discount | nf }} ₽</span>
      </div>
    </template>
		<template v-if="deliveryCost===null">
			<p class="basket-form-cost__note limited mt-8 mx-auto">Стоимость доставки и итоговая стоимость заказа<br/>будет
				рассчитана для Вас менеджером<br/>после оформления заказа</p>
		</template>
		<h3 v-else class="mt-4">ИТОГО: <span class="ml-1">{{ total | nf }} ₽</span></h3>
	</div>
</template>

<script>
import {destinations, deliveryTypes, deliveryCost} from '@/store/delivery';
import city from "@/mixins/city";

export default {
	name: "basket-form-cost",
	props: {
		form: {},
	},
	data() {
		return {}
	},
  mixins: [city],
	computed: {
		items() {
			return this.checkBasketItemsAvailability(this.$store.state.session?.basket);
		},
		destination() {
			return destinations.find(el => el.id === this.form.destinationId);
		},
		deliveryType() {
			return deliveryTypes.find(el => el.id === this.form.deliveryTypeId);
		},
		deliveryZone() {
			return this.destination?.zones?.find(el => el.id === this.form.deliveryZoneId);
		},
		/**
		 * null - не определено!
		 * 0 - бесплатно!
		 * @returns {*}
		 */
		deliveryCost() {
			return deliveryCost(this.form, this.costBeforeDelivery);
		},
		postcardsCost() {
      if (this.form.couponDiscount) {
        const postcardDiscount = Number(this.form.POSTCARD_PRICE) / 100 * Number(this.form.couponDiscount);
        return (Number(this.form.POSTCARD_PRICE) - postcardDiscount) || 0;
      }
			return Number(this.form.POSTCARD_PRICE) || 0;
		},
		itemsCost() {
      if (this.form.discountBasket) {
        let itemsTotal = 0;

        for (const [key, value] of Object.entries(this.form.discountBasket)) {
          const basketItem = this.items?.find(el => el.ID === Number(key));
          if (!basketItem?.isProductAvailable) return;
          if (basketItem?.AMOUNT > 0) {
            itemsTotal += value.PRICE * basketItem?.AMOUNT;
          }
        }

        return itemsTotal;
      }
      return this.items.reduce((sum, item) => {
        if (!item.isProductAvailable) return sum + 0;
        return sum + item.PRICE * Number(item.AMOUNT);
      }, 0);
		},
		costBeforeDelivery() {
			const productsTotal = this.itemsCost;
			const postcardCost = this.postcardsCost;
			return productsTotal + postcardCost;
		},
    discount() {
      let totalDiscount = 0;
      let postcardDiscount = 0;

      if (this.form.discountBasket) {
        for (const [key, value] of Object.entries(this.form.discountBasket)) {
          const basketItem = this.items?.find(el => el.ID === Number(key));
          if (!basketItem?.isProductAvailable) return;
          if (basketItem?.AMOUNT > 0) {
            totalDiscount += value.DISCOUNT * basketItem?.AMOUNT;
          }
        }

        postcardDiscount = Number(this.form.POSTCARD_PRICE) / 100 * Number(this.form.couponDiscount);
      }

      return totalDiscount + postcardDiscount;
    },
		total() {
			let productsTotal = this.itemsCost;
			const deliveryCost = this.deliveryCost;
			if (deliveryCost === null) return null;
			const postcardCost = this.postcardsCost;

      if (this.form.discountBasket) {
        productsTotal = 0;

        for (const [key, value] of Object.entries(this.form.discountBasket)) {
          const basketItem = this.items?.find(el => el.ID === Number(key));
          if (!basketItem?.isProductAvailable) return;
          if (basketItem?.AMOUNT > 0) {
            productsTotal += value.PRICE * basketItem?.AMOUNT;
          }
        }
      }

			return productsTotal + deliveryCost + postcardCost;
		},
	}
}
</script>

<style lang="scss">

.basket-form-cost {
	&__note {
		border: 2px solid $golden;
		//background: $golden-light;
		padding: 20px;
	}
}
</style>