<template>
	<div class="basket-item" :class="{'--mini': mini, '--unavailable': !isAvailable}">
		<v-row v-if="mini" class="align-center">
			<v-col cols="2">
				<router-link :to="to"><v-img contain :src="product.PREVIEW_PICTURE" :alt="product.NAME"/></router-link>

			</v-col>
			<v-col cols="7">
				<div class="text-start">
					<router-link :to="to" class="h5" v-html="nameHtml"></router-link>
					<div v-html="subnameHtml"></div>
					<div v-if="offer" class="basket-item__offer">{{offer.SHORT || offer.NAME}}</div>

					<!--<div v-if="content" class="basket-item__content">
											<div v-for="(flavour, index) of content" :key="'flavour'+index" class="basket-item__content-item">
												<v-img :src="flavour.PREVIEW_PICTURE"
													   :alt="flavour.clearName"
													   :title="flavour.clearName"
													   class="image"/>
											</div>
										</div>-->


				</div>
				<only-in class="mt-2" :item="item.product"/>
			</v-col>
			<v-col class="text-center relative" cols="3">

				<button class="basket-item__delete basket-item__delete--mini mt-4 mt-md-0"
						@click="onDelNoConfirm">
          <svgicon name="cross"/>
<!--					<svg width="19" height="19" viewBox="0 0 19 19" fill="#000000" xmlns="http://www.w3.org/2000/svg">
						<path d="M11.9999 12L7 7" stroke="#19191A" stroke-opacity="0.16" stroke-width="2" stroke-linecap="round"/>
						<path d="M12 7L7 12" stroke="#19191A" stroke-opacity="0.16" stroke-width="2" stroke-linecap="round"/>
					</svg>-->
				</button>

				<counter v-model="item.AMOUNT"
						 class="mx-auto mx-md-0 mt-4 mt-md-0"
						 @change="$emit('change', item)"/>

				<h5 class="color-black">{{ subtotal | nf }} ₽</h5>

			</v-col>
		</v-row>
		<v-row v-else>
			<v-col cols="12" md="8" class="d-flex flex-column flex-md-row align-center pa-0">
				<router-link :to="to">
					<v-img class="basket-item__box-img" contain :src="product.PREVIEW_PICTURE" :alt="product.NAME"/>
				</router-link>
				<!--        <img class="basket-item-item__box-img mx-auto d-block d-sm-none" :src=product.imgMobile alt="Box">-->
				<div class="mt-8 mt-md-0 ml-md-12 text-center text-md-start">
					<router-link :to="to" class="h4" v-html="nameHtml"/>
					<div v-html="subnameHtml"></div>

					<div v-if="offer" class="basket-item__offer">{{offer.SHORT || offer.NAME}}</div>

					<div v-if="content" class="basket-item__content">
						<div v-for="(flavour, index) of content" :key="'flavour'+index" class="basket-item__content-item">
							<v-img :src="flavour.PREVIEW_PICTURE"
								   :alt="flavour.clearName"
								   :title="flavour.clearName"
								   class="image"/>
						</div>
					</div>

					<only-in class="mt-5" :item="item.product"/>

				</div>
			</v-col>
			<v-col cols="12" md="4" class="pa-0 d-md-flex justify-md-end align-md-center">
				<div class="basket-item__controls text-center text-md-right d-md-flex justify-md-end align-md-center">
					<h4 class="basket-item__price mt-4 mt-md-0 d-block d-md-none">{{ subtotal | nf }} ₽</h4>
					<counter v-model="item.AMOUNT"
							 class="mx-auto mx-md-0 mt-4 mt-md-0"
							 @change="$emit('change', item)"/>

					<h4 class="basket-item__price mt-4 mt-md-0 d-none d-md-block">{{ subtotal | nf }} ₽</h4>
					<!--          todo проверить-->
					<button class="basket-item__delete mt-4 mt-md-0">
						<!--            todo v-img, svgicon-->
						<svgicon name="delete"
								 original
								 class="d-none d-md-block"
								 src="@/assets/Basket/delete.svg"
								 title="Удалить"
								 @click="onDel"/>
						<div class="d-block d-md-none size1" @click="onDel">Удалить</div>
					</button>
				</div>
			</v-col>
		</v-row>

		<divider :class="{'mt-4':mini,'mt-16':!mini}"/>

		<v-dialog
			v-model="isDeleting"
			:persistent="isDeletingConfirmed"
			:content-class="'popup'"
			max-width="400px">
			<v-card class="glass">

				<v-card-title class="pt-8 justify-center">
					<div class="h5">Вы хотите удалить этот продукт?</div>
				</v-card-title>
				<v-card-text class="text-center">
					<div v-if="deletingItem" class="h5" v-html="deletingItem.NAME"></div>
				</v-card-text>
				<v-card-actions class="d-flex justify-space-between">
          <btn-primary compact @click="isDeleting=false" :disabled="isDeletingConfirmed">Нет</btn-primary>
					<btn-primary compact :loading="isDeletingConfirmed"
                       @click="onDelConfirmed">Да</btn-primary>
				</v-card-actions>

			</v-card>
		</v-dialog>
	</div>

</template>

<script>
import Divider from "../../components/divider";
import Counter from "../../components/counter";
import BtnText from "../../components/btn-text";
import OnlyIn from "@/components/only-in.vue";
import BtnPrimary from "@/components/btn-primary.vue";

export default {
	name: "basket-item",
	components: {
    BtnPrimary,
    OnlyIn,
		BtnText,
		Divider,
		Counter
	},
	props: {
		item: Object,
		mini: {
			type: Boolean
		}
	},
	data() {
		return {
			isDeleting: false,
			isDeletingConfirmed: false,
			deletingItem: null,
		}
	},
	computed: {
		product() {
			return this.item.product;
		},
    isAvailable() {
      return this.item.isProductAvailable;
    },
		subtotal() {
			return (this.offer) ? (Number(this.offer.PRICE) * Number(this.item.AMOUNT)) : (Number(this.product.PRICE) * Number(this.item.AMOUNT));



			//return (this.offers && this.offer) ? this.offer.PRICE : this.item.PRICE;
		},
		nameHtml() {
			const item = this.product;
			//return item.name.substr(0, 1).toUpperCase() + item.name.substr(1).toLowerCase();
			let name = item.NAME;
			name = name.replace(/(^.+?)(in )?(dark|milk).+$/is, "$1");
			name = name.replace(/(^.+?)filled with .+$/is, "$1");
			name = name.replace(/(^.+?)\d+ g$/is, "$1");
			name = name.replace(/(^.+?)(\d+ pcs)$/is, "$1");
			/*if ( name.match(/(^.+?)(\d+ pcs)$/is) ) {
			  name = name.replace(/(^.+?)(\d+ pcs)$/is, "$1<br>$2");
			  if ( item.WEIGHT ) name += " / "+item.WEIGHT + " g";
			}
			else if ( item.WEIGHT ) name += "<br>"+item.WEIGHT + " g";*/

			return name;
		},
		subnameHtml() {
			const item = this.product;
			if (item.SUBNAME) return item.SUBNAME;
			//return item.choco.substr(0, 1).toUpperCase() + item.choco.substr(1).toLowerCase();
			let choco = null;
			if (item.NAME.match(/.+?filled with .+$/)) {
				choco = item.NAME.replace(/.+?(filled with.+$)/is, "$1");
				choco = choco.replace(/(.+?)(\d+ g$)/is, "$1<br>$2");
			} else if (item.NAME.match(/.+?(in )?(dark|milk).+$/)) {
				choco = item.NAME.replace(/.+?((in )?(dark|milk).+$)/is, "$1");
				choco = choco.replace(/(.+?)(\d+ g$)/is, "$1<br>$2");
			} else if (item.NAME.match(/\d+ g$/)) {
				// todo make this related to item.WEIGHT
				choco = item.NAME.replace(/.+?(\d+ g$)/is, "$1");
			} else if (item.NAME.match(/\d+ pcs$/)) {
				choco = item.NAME.replace(/.+?(\d+ pcs$)/is, "$1");
				if (item.WEIGHT) choco += " / " + item.WEIGHT + " g";
			}

			return choco;
		},
		offer() {
			return this.item.offer;
		},
		content() {
			if ( !this.item.BASKET_PROPS ) return null;
			const json = this.item.BASKET_PROPS?.find(el=>el?.NAME === "JSON");
			if ( !json ) return null;
			const ids = JSON.parse(json.VALUE);
			return ids?.map(id=>this.page.flavours?.find(f=>f.ID===id)).map(f=>({
				...f,
				clearName: f.NAME.replace(/<br\\?>/, " ")
			}));
		},
		page() {
			return this.$store.state.page.BasketController;
		},
		categoryCode() {
			const cat = this.$store.state.common.sections?.find(el => el.ID === this.product.IBLOCK_SECTION_ID);
			return cat?.CODE;
		},
		to() {
			return {
				name: 'product',
				params: {
					sectionCode: this.categoryCode,
					code: this.product.CODE
				}
			};
		},

	},
	methods: {
		add: function () {
			this.item.counter++;
		},
		sub: function () {
			if (this.item.counter > 0) this.item.counter--;
		},
		onDelConfirmed() {
			this.isDeletingConfirmed = true;

			this.$store.dispatch('post', {
				action: 'BasketDelController',
				params: {
					productId: this.deletingItem.ID
				}
			}).then((result) => {
				this.$store.commit('setSession', result);
				this.isDeleting = false;
				this.isDeletingConfirmed = false;
				this.deletingItem = null;
			}).catch((error) => {
				//console.log("ERROR: ", error);
				// this.error = error;
				this.error = "Error. Please try again"
			}).finally(() => {
				this.isDeletingConfirmed = false;
			});
		},
		onDel() {
			this.isDeleting = true;
			this.deletingItem = this.product;
			this.deletingItem.ID = this.item.PRODUCT_ID;
		},
		onDelNoConfirm() {
			this.isDeletingConfirmed = true;
			this.deletingItem = this.product;
			this.deletingItem.ID = this.item.PRODUCT_ID;

			this.$store.dispatch('post', {
				action: 'BasketDelController',
				params: {
					productId: this.deletingItem.ID
				}
			}).then((result) => {
				this.$store.commit('setSession', result);
				this.isDeleting = false;
				this.isDeletingConfirmed = false;
				this.deletingItem = null;
			}).catch((error) => {
				//console.log("ERROR: ", error);
				// this.error = error;
				this.error = "Error. Please try again"
			}).finally(() => {
				this.isDeletingConfirmed = false;
			});

		},
	},
  mounted() {
    console.log('isAvailable: ', this.item.isProductAvailable);
  }
}
</script>

<style lang="scss">
.basket-item {
	width: 100%;
	padding: 24px 0;

	&.--mini {
		padding: 8px 0;
		& .basket-item__content .image {
			width: 18px;
			height: 18px;
		}
		& .basket-item__content-item {
			margin-left: 2px;
		}

		& .counter {
			width: 68px;
			height: 28px;
			margin-bottom: 20px;
		}

		& .h3 {
			font-size: 22px;
		}

		& .counter .v-text-field input {
			font-size: 16px;
			min-width: 8px;
		}

		& .basket-item__available {
			font-family: $body-font-family;
			text-transform: uppercase;
			background-color: #eaeaea;
			padding: 4px 8px;
			width: fit-content;
			margin: 10px 0;
		}
	}

	&__available {
		font-family: $body-font-family;
		text-transform: uppercase;
		background-color: #eaeaea;
		padding: 4px 8px;
		width: fit-content;
		margin: 20px 0;
	}

	&__box {
		&-img {
			width: 160px;
			max-height: 160px;

			@include up($md) {
				max-width: 132px;
				max-height: 132px;
			}
		}
	}

	&__counter {
		width: 100%;
		max-width: 90px;
		height: 41px;
		border: 2px solid $black-50;
	}

	&__controls {
		width: 100%;

		@include up($md) {
			max-width: 320px;
		}
	}

	&__price {
		color: $black !important;
		text-align: center;

		@include up($md) {
			width: 180px;
			text-align: right;
			padding-right: 32px;
		}
	}

	&__delete {

		@include up($md) {
			width: 32px;
			height: 32px;
		}

		.size1 {
			color: $black-100;
		}

		&:hover {
			svg {
				stroke: $black;
			}
		}

		svg {
			fill: none;
			stroke: $golden;
			@include transition();
		}

		&--mini {
			position: absolute;
			top: -3px;
			right: 0;

			svg {
				width: 10px;
				height: 10px;
			}

		}

	}

	&__content {
		margin-top: 4px;
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
		justify-content: center;

		&-item {
			margin-left: 8px;
			margin-top: 8px;

			&:first-child {
				margin-left: 0;
			}
		}

		.name {

		}
		.image {
			width: 24px;
			height: 24px;
		}

		@include up($md) {
			justify-content: left;
		}
	}

	h3, h4 {
		color: $primary-500;
	}

  &.--unavailable {
    opacity: 0.4;
  }
}
</style>