import Vue from 'vue'
import Router from 'vue-router'


/*const FlavoursOriginal = () => import("../views/flavours/flavours-original");
const FlavoursCategory = () => import("../views/flavours/flavours-category");
const Praline = () => import("../views/flavours/praline");
const BoxType = () => import("../views/shop/box/box-type");
const MyBox = () => import("../views/shop/box/my-box");
const BoxLoran = () => import("../views/shop/box/box-loran");
const BoxLoran2 = () => import("../views/shop/box/box-loran2");*/

Vue.use(Router);

const routes = [
    {
        path: '/', component: () => import("../layout/layout-main"),
        children: [
            {
                path: '/',
                name: 'home',
                component: () => import("../views/Home"),
                meta: {api: "HomeController"}
            },
            {
                path: '/#shop',
                name: 'home-shop',
                component: () => import("../views/Home"),
                meta: {api: 'HomeController', hash:'shop'}
            },
            {
                path: '/#story',
                name: 'home-story',
                component: () => import("../views/Home"),
                meta: {api: 'HomeController', hash:'story'}
            },
            {
                path: '/#flavours',
                name: 'home-flavours',
                component: () => import("../views/Home"),
                meta: {api: 'HomeController', hash:'flavours'}
            },
            /*{
                path: '/flavours',
                name: 'flavours',
                component: () => import("../views/Flavours"),
                meta: {api: 'FlavoursController'}
            },*/
            {
                path: '/#corporate',
                name: 'home-corporate',
                component: () => import("../views/Home"),
                meta: {api: 'HomeController', hash:'corporate'}
            },
            {
                path: '/#stores',
                name: 'home-stores',
                component: () => import("../views/Home"),
                meta: {api: 'HomeController', hash:'stores'}
            },
            {
                path: '/#partners',
                name: 'home-partners',
                component: () => import("../views/Home"),
                meta: {api: 'HomeController', hash:'partners'}
            },
            {
                path: '/#contacts',
                name: 'home-contacts',
                component: () => import("../views/Home"),
                meta: {api: 'HomeController', hash:'contacts'}
            },

            {
                path: '/shop',
                name: 'shop',
                component: () => import("../views/Shop"),
                meta: {api: "CatalogController"}
            },
            {
                path: '/shop/:code',
                component: () => import("../layout/layout-category"),
                children: [
                    {
                        path: '/shop/:code',
                        name: 'category',
                        props: true,
                        component: () => import("../views/Category"),
                        meta: {api: "CategoryController"}
                    },
                    {
                        path: '/shop/:sectionCode/:code',
                        //alias: '/shop/:sectionCode/:code/:type',
                        name: 'product',
                        props: true,
                        component: () => import("../views/Product"),
                        meta: {api: "ProductController"}
                    },
                    /*{
                        path: '/shop/:sectionCode/:code/:type',
                        name: 'product',
                        props: true,
                        component: () => import("../views/Product"),
                        meta: {api: "ProductController"}
                    },*/
                ]
            },
            {
                path: '/box',
                name: "box",
                props: true,
                component: () => import("../views/Category"),
                meta: {api: "CategoryController"}
            },
            {
                path: '/flavours/:code',
                name: 'flavour',
                component: () => import("../views/Flavour"),
                meta: {api: "FlavourController"}
                /*component: () => import("../views/Flavours"),
                children: [
                    {
                        path: '/flavours',
                        name: 'flavours-category',
                        component: FlavoursCategory,
                    },
                    {
                        path: '/flavours/original',
                        name: 'flavours-original',
                        component: FlavoursOriginal,
                    },
                    {
                        path: '/flavours/original/praline',
                        name: 'praline',
                        component: Praline,
                    },
                ]*/
            },
            {
                path: '/terms',
                name: 'terms',
                component: () => import("../views/Terms"),
                meta: {api: 'PageController', code: 'terms'}
            },
            {
                path: '/basket',
                name: 'basket',
                component: () => import("../views/Basket"),
                meta: {api: "BasketController"}
            },
            {
                path: '/order/:id',
                name: 'order',
                component: () => import('@/views/Order'),
                meta: {api: "OrderController", isHeaderInversed: true}
            },
            {
                path: '/order/status/:hash',
                name: 'order-status',
                component: () => import('@/views/OrderStatus'),
                meta: {api: "OrderStatusController", isHeaderInversed: true /*, routingLoadingId: "order"*/}
            },
            {
                path: '/privacy',
                name: 'policy',
                component: () => import("../views/Policy"),
                meta: {api: 'PageController', code: 'privacy'}
            },
            /*{
                path: '/delivery',
                name: 'delivery',
                component: () => import("../views/Delivery"),
                meta: {api: "DealersController"}
            },*/
            {
                path: '/where-to-buy',
                name: 'places',
                component: () => import("../views/Places"),
                meta: {api: "PlacesController"}
            },
            {
                path: '/error404',
                name: 'error404',
                component: () => import("../views/Error404"),
                meta: {api: 'PageController', code: 'error404'}
            },
            {
                path: '/error500',
                name: 'error500',
                component: () => import("../views/Error500"),
                meta: {api: 'PageController', code: 'error500'}
            },
            {
                path: '/under-construction',
                name: 'under',
                component: () => import("../views/UnderConstruction"),
                meta: {api: 'PageController', code: 'under-construction'}
            },
            {
                path: '*',
                //name: 'error404',
                component: () => import("../views/Error404"),
            },

        ]
    }
];
export default routes;