<template>
	<v-btn class="btn-primary"
		   :class="{large:large}"
		   elevation="0"
		   :loading="loading"
		   :disabled="disabled"
       :block="block"
		   @click="$emit('click')">
		<slot name="default">{{label}}</slot>
	</v-btn>
</template>

<script>
	export default {
		name: "btn-primary",
		props: {
			label: {},
			loading: {},
			large: {
				type: Boolean,
				default: false
			},
			disabled: {
				type: Boolean,
				default: false
			},
      block: {
        type: Boolean,
        default: false,
      }
		}
	}

</script>

<style lang="scss">
	.btn-primary {
		display: inline-block;
		height: 32px !important;
		border: 2px solid $golden;
		background: transparent !important;
		color: $golden !important;
		font-family: $header-font-family;
		font-size: $fs-h6 !important;
		//font-weight: $fw-book !important;
		line-height: $lh-h6 !important;
		letter-spacing: $ls-h6 !important;
		text-transform: uppercase;
		box-sizing: border-box;
		padding: 0 28px !important;
		border-radius: 0 !important;

		@include transition();

		&:hover:not(:disabled), &:active:not(:disabled) {
			border-color: transparent;
			background: $golden !important;
			color: $white !important;
		}

		&.v-btn--disabled.v-btn--has-bg:disabled {
			border: 2px solid $black-50;
			background: transparent !important;
			color: $black-50 !important;
			//cursor: default;
		}

		&.large {
			height: 50px !important;
			font-size: $fs-h5 !important;
			line-height: $lh-h5 !important;
			letter-spacing: $ls-h5 !important;
		}

		&.block {
			display: block;
		}
	}


</style>