<template>
  <section class="section--padding text-center contacts">
    <v-container>
      <h2>Контакты</h2>


      <h4 class="mt-10 color-black">Интернет-магазин</h4>
      <div class="color-black">09:00 - 21:00</div>
      <v-row class="mt-10 justify-center h5 text-center">
        <v-col v-for="(contact, index) in contacts.slice(0, 3)" :key="index" cols="12" md="3">
          <a class="color-black" :href="'tel:' + contact.PHONE.replace(/[^\d+]/g, '')">{{ contact.NAME }} <br>{{ contact.PHONE }}</a>
        </v-col>
      </v-row>

      <h5 class="mt-10 contacts__opinion color-black">Нам важно знать ваше мнение
        <a href="mailto:factory@schastye.com">factory@schastye.com</a>
      </h5>

      <divider class="mt-15"/>

      <v-row class="mt-15">
        <v-col
            v-for="(contact, index) in contacts.slice(3)"
            :key="index"
            cols="12"
            sm="6"
            lg="4"
            class="mt-8 mt-sm-0 contacts__col"
        >
          <h4 class="color-black">{{ contact.NAME }}</h4>
          <div class="contacts__person mt-8">
            <h5 class="color-black">{{ contact.EMPLOYEE }}</h5>
            <div class="size1 my-2" v-html="contact.JOB"></div>
          </div>
          <div class="h5 mb-2">
            <a class="color-black" :href="'tel:' + contact.PHONE.replace(/[^\d+]/g, '')">{{ contact.PHONE }}</a>
          </div>
          <div class="h5">
            <a :href="'mailto:' + contact.EMAIL">{{ contact.EMAIL }}</a>
          </div>
        </v-col>
      </v-row>

    </v-container>
  </section>
</template>

<script>
import Divider from "../../components/divider";

export default {
  name: "contacts",
  components: {Divider},
  computed: {
    contacts() {
      return this.$store.state?.common.contacts;
    },
  },
  mounted() {
    console.log('common: ', this.$store.state.common);
  }
}
</script>

<style lang="scss">
.contacts {
  background: $primary-50;

  @include up($md) {
    padding: 130px 0;
  }

  h4 {
    color: $black !important;
  }

  &__person {
    min-height: 85px;
  }

  a {
    //color: $black !important;
    /*font-family: $header-font-family;
    font-size: $fs-h5;
    line-height: $lh-h5;
    letter-spacing: $ls-h5;*/

    /*@include up($md) {
      font-size: $fs-h4;
      line-height: $lh-h4;
      letter-spacing: $ls-h4;
    }*/

    &:hover {
      color: $golden !important;
    }
  }

  &__opinion {
    //font-family: $body-font-family;
    a {
      color: $golden !important;
    }
  }

  &__col {
    height: 240px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
</style>