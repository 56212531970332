<template>
  <v-app>
    <transition name="route" mode="out-in">
      <router-view/>
    </transition>
  </v-app>
</template>

<script>
import config from "@/config";
import {DESTINATION_MSK, DESTINATION_SPB, destinations} from "@/store/delivery";
import {objectToArray} from "@/utils/utils";

export default {
  name: 'App',
  data: () => ({
    swiperReinitCount: 0,
  }),
  computed: {
    isWindowMD() {
      return this.$store.getters.isWindowMD;
    },
    deliveryCosts() {
      return this.$store.state.common.deliveryCosts;
    },
    isAllFetched() {
      return this.$store.state.isAllFetched;
    }
  },
  methods: {
    onResize() {
      //console.log("App onResize");

      this.$store.commit('updateWindowWidth');
      this.$store.commit('updateWindowHeight');

      // на iOS чтобы попадало во весь экран на телефоне 100vh:
      //нужно добавить css переменную vh в main.scss:
      //:root {
      //--vh: 1vh;
      //}
      //после вместо vh использовать var(--vh, 1vh)
      //например всето 100vh = calc(var(--vh, 1vh)*100)
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);

      this.reinitSwipers();

      if (this.isWindowMD) {
        this.$store.state.withBurgerMenu = false;
        this.$store.state.withBasketPopup = false;
      }
    },
    reinitSwipers() {

      /*
      TODO важно! уродский свафпер не пересоздается при ресайзе/уничтожении в v-if
      TODO приходится его полностью пересоздавать (точнее обновлять его ref)
      https://github.com/surmon-china/vue-awesome-swiper/issues/774
      https://github.com/surmon-china/vue-awesome-swiper/issues/687
      */
      this.swiperReinitCount++;   // это важно!

      setTimeout(() => {
        // обновим референс на свайпер для доступа снаружи
        // TODO пройти по всем свайперам на странице и реинитить их
        //this.$store.state.appSwiper = this.swiper;
        //console.log("APP SWIPER reinited", this.swiper);
        let keys = Object.keys(this.$store.state.swipers);
        keys.forEach(key => {
          if (this.$store.state.swipers[key]) this.$store.state.swipers[key].update();
        });
      }, 300);
    },
    runSessionRefresh() {
      this.$store.dispatch('fetchSession').then(() => {
        console.log("Session refreshed");
      })
          .catch(() => {
          })
          .finally(() => {
            setTimeout(() => {
              this.runSessionRefresh();
            }, config.SESSION_REFRESH_TIMEOUT);
          });
    },
    updateDeliveryCosts(_destinations, deliveryCosts) {
      objectToArray(deliveryCosts).forEach(cost => {
        if (!cost.VALUE) return;

        let regionId = null;

        const [region, deliveryType, deliveryZone] = cost.CODE.split('DELIVERY_COST_')[1].split('_');

        if (region === 'MSK') regionId = DESTINATION_MSK;
        if (region === 'SPB') regionId = DESTINATION_SPB;

        if (deliveryType === 'KM') {
          _destinations.find(dest => dest.id === regionId).unknownZonePricePerKm = cost.VALUE;
        }

        if (deliveryType === 'D') {
          _destinations
              .find(dest => dest.id === regionId).zones
              .find(zone => zone.number === Number(deliveryZone))
              .price = Number(cost.VALUE);
        }
        if (deliveryType === 'E') {
          _destinations
              .find(dest => dest.id === regionId).zones
              .find(zone => zone.number === Number(deliveryZone))
              .priceExpress = Number(cost.VALUE);
        }
      });

      this.$store.commit('setDestinations', _destinations);
    }
  },
  watch: {
    deliveryCosts(costs) {
      if (!costs) return;
      this.updateDeliveryCosts(destinations, this.deliveryCosts);
    }
  },
  mounted() {
    window.addEventListener("resize", this.onResize);
    this.onResize();

    setTimeout(() => {
      this.runSessionRefresh();
    }, config.SESSION_REFRESH_TIMEOUT);
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize);
  }
};
</script>

<style lang="scss">

#app {
  //overflow-x: hidden;	commented in order .sticky to work
  min-width: 320px;
}

// todo review all below ==========================

.my-primary {
  color: $primary-500;
}

.my-black {
  color: $black;
}


</style>
