<template>
	<div class="basket-form-destinations text-center" id="basket-form-destinations">
		<h4>Пункт назначения</h4>

		<div class="mt-2 d-flex justify-center">
<!--			<v-radio-group v-model="form.destinationId" :row="isSM" @change="onSelect">
				<v-radio v-for="(item, index) of destinations"
						 :label="item.label"
						 :value="item.id"
						 :key="'destination'+index"/>
			</v-radio-group>-->
      <p v-if="isOtherCity">Выберите вариант получения заказа</p>
      <p v-else-if="city">Выберите вариант получения заказа в г. {{ city }}</p>
      <p v-else>Не удалось определить город</p>
		</div>
		<p v-if="isCourierOnly" class="mt-2">Для выбранного пункта назначения возможна только курьерская доставка</p>
	</div>
</template>

<script>
	import vp from '@/mixins/vp';
	import {scrollTo} from '@/utils/utils';
	import {date2dmy} from '@/utils/date';
	import {destinations, DESTINATION_SPB, DESTINATION_MSK, DELIVERY_TYPE_EXPRESS} from '@/store/delivery';
  import city from "@/mixins/city";
  import config from "@/config";

	export default {
		name: "basket-form-destinations",
		mixins: [vp, city],
		props: {
			form: {},
		},
		data() {
			return {
			}
		},
		computed: {
			destinations() {
				return destinations;
			},
			destination() {
				return this.destinations.find(el => el.id === this.form.destinationId);
			},
			isCourierOnly() {
				return this.destination && !this.destination.deliveryTypeIds?.length;
			},
      city() {
        return this.currentCity;
      },
      isOtherCity() {
        return this.city === config.CITIES[2].name;
      },
      findedCity() {
        if (!this.city) return null;
        const findedC = config.CITIES.find(el => el.name === this.city);
        if (!findedC) return config.CITIES[2];
        return findedC;
      }
		},
		methods: {
			onSelect() {
        this.form.CITY = this.destination.city !== 'Другой город' ? this.destination.city : "";
				this.form.STREET = null;
				this.form.deliveryTypeId = null;
				this.form.deliveryZoneId = null;

				/*setTimeout(() => {
					scrollTo("basket-form-destinations", -120);
				}, 300);*/

				this.$emit("select");
			},
			fillForm() {
				//this.form.STREET = "Решетникова 15";
				this.form.destinationId = DESTINATION_SPB;
				this.form.deliveryTypeId = DELIVERY_TYPE_EXPRESS;
				this.form.deliveryZoneId = 202;
				this.form.DATE_DELIVERY = date2dmy(new Date());
				this.form.TIME = "с 10:00 до 13:00";
				this.form.CITY = this.destination?.city || "Новгород";
				this.form.STREET = "Решетникова 15";
				this.form.APT = "122";
				this.form.NAME = "PlayNext";
				this.form.EMAIL = "test@playnext.ru";
				this.form.PHONE = "+78126430280";
				this.form.COMMENTS = "тест, тест";
				this.form.hasAccepted = true;
			}
		},
    watch: {
      city(newCity) {
        this.form.destinationId = this.findedCity?.destinationId || config.CITIES[2].destinationId;
        this.form.CITY = this.city ? this.city : null;
        this.form.STREET = null;
        this.form.deliveryTypeId = null;
        this.form.deliveryZoneId = null;
      }
    },
    mounted() {
      if (!this.city) {
        this.form.destinationId = null;
      } else {
        this.form.destinationId = this.findedCity.destinationId;
        this.form.CITY = this.city;
        this.form.STREET = null;
        this.form.deliveryTypeId = null;
        this.form.deliveryZoneId = null;
      }
    }
  }
</script>

<style lang="scss">

</style>