<template>
	<div class="product-without-content">
		<v-container>
			<v-row class="align-md-center justify-space-between">
				<v-col cols="12" md="6" class="text-center">

					<div class="product-without-content__image">
						<div class="product-without-content__badge">
							<div class="limited" v-if="item.LIMITED">édition limitée</div>
							<div v-if="item.NEW">new</div>
						</div>
						<div class="hover" :class="{pointer:photo1(item)}">
							<v-img :src="photo0(item)"
								   class=""
								   :class="{closed:photo1(item)}"
								   contain
								   :alt="item.NAME"/>
							<v-img v-if="photo1(item)" :src="photo1(item)" class="open" contain :alt="item.NAME"/>
						</div>
					</div>

				</v-col>
				<v-col cols="12" md="6" class="text-center text-md-left pl-md-8">
					<h1 v-html="nameHtml"></h1>
					<h5 v-html="chocoHtml" class="mt-2"></h5>
					<h5 v-if="$route.params.sectionCode === 'chocolate-tablets'" v-html="item.SUBNAME" class="mt-2"></h5>

					<p class="mt-8 product-without-content__note">
						<!--<template v-if="!!flavourId && !item.DETAIL_TEXT">
						  Ваша конфетка уже в этой коробке.
						</template>
						  <template v-else-if="item.DETAIL_TEXT" v-html="item.DETAIL_TEXT">

						  </template>
						<template v-else>
						  Эта коробка наполнена ассорти вкусов по нашей рекомендации.
						  Если Вы предпочитаете конкретный вкусы - сообщите нам, и мы заполним коробку по Вашему предпочтению.
						</template>-->
					</p>
					<p v-if="!!flavourId && !item.DETAIL_TEXT"
					   class="mt-8 product-without-content__note">
						Ваша конфетка уже в этой коробке.
					</p>
					<p v-else-if="item.DETAIL_TEXT" v-html="item.DETAIL_TEXT"
					   class="mt-8 product-without-content__note">
					</p>
<!--					<p v-else-if="offers && foundOffer" v-html="foundOffer.DETAIL_TEXT"
					   class="mt-8 product-without-content__note">
					</p>-->

					<p v-else class="mt-8 product-without-content__note">
						Эта коробка наполнена ассорти вкусов по нашей рекомендации.
						Если Вы предпочитаете конкретные вкусы - сообщите нам, и мы заполним коробку по Вашему
						предпочтению.
					</p>

					<!--<div v-if="item.PREVIEW_TEXT" class="size1 color-golden mt-1">{{item.PREVIEW_TEXT}}</div>-->

					<div class="h3 color-black mt-4 d-none">{{ price | price(2) }} ₽</div>

					<!--<div class="mt-10 d-none d-md-block">
					  <div><router-link :to="flavourRoute" class="size2">About the product</router-link></div>
					</div>-->

					<transition name="fade">
						<div class="product-without-content__price d-flex flex-column align-center align-md-start">

							<!-- TODO: реализовать 3 алгоритма -->

							<div v-if="$route.params.sectionCode === 'torty' && offers"
								 class="product-without-content__cake-size mt-4">
								<div v-for="(offer, index) in offers"
									 :key="'index' + index"
									 class="cake-size-item"
									 :class="{'cake-size-item--active' : offerIndex == index}"
									 @click="onOfferChosen(offer, index)"
								>
									<v-img :src="offer.PREVIEW_PICTURE"></v-img>
									<span>{{ offer.NAME }}</span>
								</div>
							</div>



<!--							<div v-if="$route.params.sectionCode === 'mini-pralines' && offers"
								 class="product-without-content__choco-types mt-4">
								<div v-for="(item, index) in chocoTypes"
									 :key="'index' + index"
									 class="btn-primary large choco-types-item mb-2 mb-lg-0"
									 :class="{'choco-types-item&#45;&#45;active': item.active}"
									 @click="onChocoTypeChosen(item, index)"
								>
									<span>{{ item.size }}</span>
								</div>


							</div>-->

							<p v-if="offers && offer" v-html="offer.DETAIL_TEXT"
							   class="mt-8 product-without-content__note">
							</p>

							<div v-if="isProductAvailable" class="d-flex mt-12 mt-md-8 align-center">
								<!--<btn-primary class="large d-md-none"
									   :label="'Add to cart '+priceText+' ₽'"
									   :loading="isLoading"
									   @click="onAddToCart"/>-->
								<transition name="fade-quick" mode="out-in">
									<router-link v-if="isItemInBasket" :to="{name: 'basket'}" class="--without-hover">
                    <btn-primary class="product-without-content__add-to-basket-button large"
                                 label="В корзине"
                                 :loading="adding"/>
                  </router-link>
									<btn-primary v-else class="product-without-content__add-to-basket-button large"
												 label="В корзину"
												 :loading="adding"
                         :disabled="!isProductAvailable"
												 @click="onAddToBasket"/>
								</transition>

								<div class="h3 color-black d-md-inline ml-8 ml-md-16">{{ price | price }} ₽</div>
							</div>
              <div v-else class="mt-12 mt-md-8 h5">{{ unavailableInYourRegionText }}</div>
						</div>
					</transition>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
import BtnPrimary from "@/components/btn-primary";
import {nf} from '@/utils/string';
import {scrollTo} from '@/utils/utils';
import actions from "../../store/actions";
import config from "@/config";

export default {
	name: "product-without-content",
	components: {
		BtnPrimary,
	},
	props: ["adding", "code", "sectionCode", "isProductAvailable"],
	data() {
		return {
			colsImage: 6,
			colsText: 6,
			isCustom: false,
			isLoading: false,
			//selectedOfferId: null,
			offerIndex: 0,
		}
	},
	computed: {
    city() {
      return this.currentCity;
    },
    unavailableInYourRegionText() {
      return config.UNAVAILABLE_IN_YOUR_REGION_TEXT;
    },
		flavourId() {
			return this.$route.query?.flavourId;
		},
		page() {
			return this.$store.state.page.ProductController;
		},
		/*flavourRoute(item) {
		  //let anchor = "";
		  //if (this.section.UF_FLAVOUR_SECTION_IDS) anchor = "#section" + this.section.UF_FLAVOUR_SECTION_IDS[0];

		  return {
			name:'flavour',
			params:{
			  code:item.CODE
			},
			query: {
			  back: this.$route.path
			}
		  };
		},*/
		categories() {
			return this.$store.state.common.sections;
		},
		item() {
			return this.page.item;
		},
		offers() {
			return this.page.offers;
		},
		offer() {
			return this.offers ? this.offers[this.offerIndex] : null;
		},
		section() {
			return this.page.section;
		},
		price() {
			return (this.offers && this.offer) ? this.offer.PRICE : this.item.PRICE;
		},
		isItemInBasket() {
			return this.$store.state.session?.basket?.find(el => Number(el.PRODUCT_ID) === Number(this.item.ID));
		},
		priceText() {
			return nf(this.item.PRICE, 2);
		},
		nameHtml() {
			const item = this.item;
			//return item.name.substr(0, 1).toUpperCase() + item.name.substr(1).toLowerCase();
			let name = item.NAME;
			name = name.replace(/(^.+?)(in )?(dark|milk).+$/is, "$1");
			name = name.replace(/(^.+?)filled with .+$/is, "$1");
			name = name.replace(/(^.+?)\d+ g$/is, "$1");
			name = name.replace(/(^.+?)(\d+ pcs)$/is, "$1");
			/*if ( name.match(/(^.+?)(\d+ pcs)$/is) ) {
			  name = name.replace(/(^.+?)(\d+ pcs)$/is, "$1<br>$2");
			  if ( item.WEIGHT ) name += " / "+item.WEIGHT + " g";
			}
			else if ( item.WEIGHT ) name += "<br>"+item.WEIGHT + " g";*/

			return name;
		},
		chocoHtml() {
			const item = this.item;
			//return item.choco.substr(0, 1).toUpperCase() + item.choco.substr(1).toLowerCase();
			let choco = null;
			if (item.NAME.match(/.+?filled with .+$/)) {
				choco = item.NAME.replace(/.+?(filled with.+$)/is, "$1");
				choco = choco.replace(/(.+?)(\d+ g$)/is, "$1<br>$2");
			} else if (item.NAME.match(/.+?(in )?(dark|milk).+$/)) {
				choco = item.NAME.replace(/.+?((in )?(dark|milk).+$)/is, "$1");
				choco = choco.replace(/(.+?)(\d+ g$)/is, "$1<br>$2");
			} else if (item.NAME.match(/\d+ g$/)) {
				// todo make this related to item.WEIGHT
				choco = item.NAME.replace(/.+?(\d+ g$)/is, "$1");
			} else if (item.NAME.match(/\d+ pcs$/)) {
				choco = item.NAME.replace(/.+?(\d+ pcs$)/is, "$1");
				if (item.WEIGHT) choco += " / " + item.WEIGHT + " g";
			}

			return choco;
		},
	},
	methods: {
		onAddToBasket() {
			//this.$emit("basket-add");
			const flavourIds = this.cells?.filter(el => el?.ID).map(el => (el.ID));
			this.$emit("basket-add", this.item, flavourIds, this.offer?.ID);
			//setTimeout(() => this.onClear(), 750);
		},
		onOfferChosen(offer, index) {
			this.offerIndex = index;
			if (this.offerIndex !== index) {
				this.offerIndex = index;
			}
			console.log('offer.isActive', this.offerIndex);


		},
		photo0() {
			if (this.item.PHOTOS && this.item.PHOTOS[0]) return this.item.PHOTOS[0];
			return this.item.DETAIL_PICTURE;
		},
		photo1() {
			//if ( this.item.DETAIL_PICTURE ) return null;
			return this.item.PHOTOS && this.item.PHOTOS[1];
		},


	},
	mounted() {
		//this.offerIndex = 0;
	}
}
</script>

<style lang="scss">
.product-without-content {
	&__badge {
		display: flex;
		justify-content: center;
		position: absolute;
		left: 0;
		z-index: 3;
		div {
			padding: 2px 8px;
			color: #FFF;
			font-size: 12px;
			font-weight: 300;
			letter-spacing: 0.96px;
			text-transform: uppercase;
			//border: 1px solid #D6BF96;
			background: #DFC391;
			margin-bottom: 4px;
			width: fit-content;
		}
		& .limited {
			background: #9FA37B;
		}
	}

	&__image {
		position: relative;
		//width: 125px;
		height: 320px;
		@include transition();
		@include up($md) {
			//width: 380px;
			height: 380px;
		}

		.hover {
			position: absolute;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
			z-index: 2;
			//width: 100%;
			height: 100%;
			//background: rgba(0,0,0,0.2);
			//cursor: pointer;

			&:hover {
				opacity: 1;
			}

			&:hover .open {
				opacity: 1;
			}

			&:hover .closed {
				opacity: 0;
			}
		}

		.v-image {
			height: 100%;
			//mix-blend-mode: multiply;
			//background-blend-mode: exclusion;
			filter: brightness(1.01); // contrast(1.02);	// для исчезновения в jpg серого фона
			@include transition();

			&.open {
				position: absolute;
				top: 0;
				left: 0;
				z-index: 1;
				opacity: 0;
			}
		}
	}

	&__note {
		max-width: 480px;
		margin: 0 auto;
		@include up($md) {
			margin: 0;
		}
		ul {
			padding-left: 24px !important;
		}
		ul, li {
			list-style: disc !important;
		}
	}

  &__add-to-basket-button {
    min-width: 140px !important;
  }

	&__cake-size {
		display: flex;
		align-items: center;
		gap: 25px;
		text-align: center;

		.cake-size-item {
			cursor: pointer;

			.v-image {
				margin-bottom: 10px;
				border: 2px solid transparent;
				border-radius: 50px;
				@include transition();
			}
		}

		.cake-size-item--active {
			.v-image {
				box-shadow: 0 0 0 2px $golden;
			}
		}

		.cake-size-item.active {
			.v-image {
				box-shadow: 0 0 0 2px $golden;
			}
		}
	}

	&__choco-types {
		display: block;
		align-items: center;
		gap: 25px;
		text-align: center;

		@include up($sm) {
			display: flex;
		}
		@include up($md) {
			display: block;
			width: 100%;
		}
		@include up($lg) {
			display: flex;
		}

		.choco-types-item {
			cursor: pointer;
			display: flex;
			align-items: center;

			.v-image {
				margin-bottom: 10px;
				border: 2px solid transparent;
				border-radius: 50px;
				@include transition();
			}
		}

		.choco-types-item--active {
			border: 2px solid #C09C5E;
			background: #C09C5E !important;
			color: #fff !important;
		}

		.choco-types-item:hover {
			border: 2px solid #C09C5E;
			background: #C09C5E !important;
			color: #fff !important;
		}
	}
}
</style>