//import config from '../config'

let state = {

    response: {},	// объект ответа от БЕ в чистом виде
    session: {},	// данные текущей сессии
    common: {},			// общие для всех страниц
    page: {},			// индивидуальные для каждой страницы
    currentPage: {},	// текущая страница
    isLoading: false,

    // глобальные параметры UI
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight,
    isHeaderInversed: false,
    headerHeight: 64,
    withBurgerMenu: false,
    withBasketPopup: false,

    isBasketChangeCityPopupOpen: false,
    isSelectCityPopupOpen: false,
    cityId: null,
    cityName: null,

    destinations: null,

    // главное меню
    menuItems: [
        {
            name: "Онлайн-магазин",
            to: {name:"home-shop"},
            dropdown: [
                {
                    name: 'Пралине',
                    to: 'shop/original-pralines'
                },
                {
                    name: 'Мини-пралине',
                    to: 'shop/mini-pralines'
                },
                {
                    name: 'Ириски',
                    to: 'shop/creamy-caramels'
                },
                {
                    name: 'Шоколад ',
                    to: 'shop/chocolate-tablets'
                },
                {
                    name: 'Маленьким ангелам',
                    to: 'shop/kids'
                },
                {
                    name: 'Аксессуары',
                    to: 'shop/accessory'
                },
                {
                    name: 'Трюфели',
                    to: 'shop/truffles'
                },
                {
                    name: 'Домашние конфеты',
                    to: 'shop/domashnie-konfety'
                },
                {
                    name: 'Макарони',
                    to: 'shop/makaroni'
                },
                {
                    name: 'Печенье',
                    to: 'shop/pechene'
                },
                {
                    name: 'Торты',
                    to: 'shop/torty'
                },
                {
                    name: 'Подарки',
                    to: 'shop/podarochnye-nabory'
                }
            ],
            id: "shop"
        },
        {name: "Мы создаем", to: {name:"home-flavours"}, id: "flavours"},
        {name: "Мы", to: {name:"home-story"}, id: "story"},
        {name: "Подарки с нами", to: {name:"home-corporate"}, id: "corporate"},
        //{name: "Souvenirs", to: {name:"category", params: {code:"souvenirs"}}},
        //{name: "Special occasion", to: {name:"home-special"}},
        //{name: "Our stores", to: {name:"home-stores"}},
        {name: "Где нас купить", to: {name:"places"}},
        {name: "Контакты", to: {name:"home-contacts"}, id: "contacts"},
        //{name: "Доставка", to: {name:"home-contacts"}},
        //{name: "Terms of sale", to: {name:"terms"}},
    ],
    selectedMenuItemId: null,

    // настройки языка
    currentLanguageCode: "EN",
    languages: [
        {
            code: "EN",
            name: "English language",
        },
        {
            code: "FR",
            name: "Française",
        }
    ],



    // todo reconsider all below =====

    // todo do we still need this
    swipers: {
        // todo fill correct keys
        /*'collection-slider': null,
        'ambassadors-slider-sm': null,
        'ambassadors-slider-lg': null*/
    },

    isPopupFlavours: false,
    myBoxImages: [
        require('@/assets/box/plus.svg'),
        require('@/assets/box/plus.svg'),
        require('@/assets/box/plus.svg'),
        require('@/assets/box/plus.svg'),
        require('@/assets/box/plus.svg'),
        require('@/assets/box/plus.svg'),
        require('@/assets/box/plus.svg'),
        require('@/assets/box/plus.svg'),
        require('@/assets/box/plus.svg')
    ],

};

export default state;
