<template>
  <div class="select-city" :class="{'--inversed': isInversed}">
    <div class="select-city__content d-flex align-center justify-md-center" @click="onClick">
      <div class="select-city__icon">
        <v-img src="@/assets/geo.svg"/>
      </div>
      {{ currentCity }}
    </div>

    <div v-if="isConfirmShown" class="select-city__confirm d-none d-md-flex flex-column">
      <div class="color-black mb-2">Ваш город - {{ currentCity }}?</div>
      <btn-primary @click="onYesClick">Да</btn-primary>
      <btn-primary @click="onNoClick" class="select-city__confirm-no">Нет</btn-primary>
    </div>

    <select-city-popup/>
  </div>
</template>

<script>
import Cookies from 'js-cookie';
import city from "@/mixins/city";
import BtnPrimary from "@/components/btn-primary.vue";
import BtnText from "@/components/btn-text.vue";
import SelectCityPopup from "@/components/select-city-popup.vue";
import selectCityPopup from "@/components/select-city-popup.vue";

export default {
  name: 'select-city',
  components: {SelectCityPopup, BtnText, BtnPrimary},
  mixins: [city],
  props :{
    isInversed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isConfirmShown: false,
      selectedCity: null,
    }
  },
  methods: {
    onClick() {
      this.$store.commit('setIsSelectCityPopupOpen', true);
    },
    onYesClick() {
      localStorage.setItem('isCityConfirmed', '1');
      this.selectedCity = this.currentCity;
      this.isConfirmShown = false;
    },
    onNoClick() {
      this.$store.commit('setIsSelectCityPopupOpen', true);
      this.isConfirmShown = false;
    },
  },
  mounted() {
    const isCityConfirmed = localStorage.getItem('isCityConfirmed');
    if (!isCityConfirmed) {
      this.isConfirmShown = true;
    }
  }
}
</script>

<style lang="scss">
.select-city {
  position: relative;

  &__content {
    color: $black;
    cursor: pointer;
    min-width: 150px;
    margin-left: -10px;
    @include transition();

    @include up($md) {
      margin-left: 0;
    }

    &:hover {
      opacity: 0.7;
    }

    .select-city__icon {
      width: 33px;
      height: 33px;
    }

    .v-image {
      filter: brightness(0) invert(0);
    }
  }

  &.--inversed {
    .select-city__content {
      color: $white;

      .v-image {
        filter: brightness(0) contrast(500%) invert(1);
      }
    }
  }

  &__confirm {
    position: absolute;
    top: calc(100% + 10px);
    left: 0;
    background: $white-alpha;
    padding: 15px 20px;
    border-radius: 10px;
    gap: 8px;
    width: 220px;
    box-shadow: 5px 6px 30px -10px rgba(0,0,0,0.75);

    &-no {
      border: none !important;
    }
  }
}
</style>