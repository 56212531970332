<template>
  <div class="page--padding shop">
    <section class="section--page">
      <category-list :items="categoriesInCity"/>
    </section>
    <section class="section--image shop__bg"/>
  </div>
</template>

<script>
import CategoryList from "../components/category-list";
import city from "@/mixins/city";

export default {
  name: "Shop",
  components: {
    CategoryList,
  },
  data() {
    return {}
  },
  mixins: [city],
  computed: {
    city() {
      return this.$store.state.common.city;
    },
    categories() {
      return this.$store.state.common.sections;
    },
    categoriesInCity() {
      return this.getAvailableInCityCategories(this.categories);
    }
  }
}
</script>

<style lang="scss">
.shop {
  &__bg {
    margin-top: 50px;
    background-image: url("~@/assets/Shop/shop-img.jpeg");
  }
}
</style>