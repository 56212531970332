<template>
  <div class="only-in size4 d-inline-block">
    <div v-if="item.NO_SPB && item.NO_MSK">Sold out</div>
    <div v-else-if="item.NO_SPB">Только в Москве</div>
    <div v-else-if="item.NO_MSK">Только в Петербурге</div>
    <div v-else class="d-none"></div>
  </div>
</template>

<script>
export default {
  name: 'only-in',
  props: {
    item: {
      type: Object,
      required: true,
    },
  }
}
</script>

<style lang="scss">
.only-in {
  div {
    font-family: $body-font-family;
    text-transform: uppercase;
    background-color: #eaeaea;
    padding: 4px 8px;
  }
}
</style>