<template>
  <v-dialog v-model="isShown" content-class="basket-change-city-popup__dialog">
    <v-card class="basket-change-city-popup">
      <div class="basket-change-city-popup__text h5">
        <div>Для указанного адреса возможна доставка только с помощью СДЭК. Выбран другой город. Ассортимент доступной для заказа продукции может отличаться.</div>
      </div>
      <div class="basket-change-city-popup__button d-flex justify-center mt-8">
        <btn-primary @click="onOkClick">ОК</btn-primary>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import city from "@/mixins/city";
import BtnPrimary from "@/components/btn-primary.vue";
import config from "@/config";

export default {
  name: 'basket-change-city-popup',
  components: {BtnPrimary},
  emits: ['close'],
  mixins: [city],
  props: {
    form: {
      type: Object,
    }
  },
  computed: {
    isShown: {
      get() {
        return this.$store.state.isBasketChangeCityPopupOpen;
      },
      set(newValue) {
        this.$store.commit('setIsBasketChangeCityPopupOpen', newValue);
      }
    }
  },
  methods: {
    onOkClick() {
      this.$store.commit('setCurrentCity', {cityId: config.CITIES[2].id, cityName: config.CITIES[2].name});
      localStorage.setItem('cityName', config.CITIES[2].name);
      localStorage.setItem('cityId', config.CITIES[2].id);

      this.$store.dispatch('post', {
        action: 'CityChangeController',
        params: {
          cityId: config.CITIES[2].id,
        }
      }).then(() => {
        // then
      }).catch((err) => {
        //console.error('Не удалось сохранить город. Ошибка: ', err);
        // error
      }).finally(() => {
        this.loading = false;
      });

      this.form.CITY = '';
      this.$store.commit('setIsBasketChangeCityPopupOpen', false);
    },
  }
}
</script>

<style lang="scss">
.basket-change-city-popup {
  padding: 30px 30px;
  border-radius: 0 !important;

  &__dialog {
    border-radius: 0 !important;
    box-shadow: none !important;
    max-width: 90% !important;

    @include up($md) {
      max-width: 30% !important;
    }
  }
}
</style>