<template>
  <div class="basket-form-delivery-date text-center" id="basket-form-delivery-date">
    <h4>Дата и время {{ isSelf ? "получения" : "доставки" }}</h4>

    <v-row class="mt-2 justify-center">
      <v-col cols="12" sm="4">
        <v-text-field
            v-model="form.DATE_DELIVERY"
            label="Дата"
            placeholder="ДД.ММ.ГГГГ"
            prepend-icon="mdi-calendar"
            hide-details dense
            readonly
            maxlength="10"
            :rules="rulesDate"
            @click:prepend="isDateModal=true"
            @click="isDateModal=true"/>

        <v-dialog ref="dateModal"
                  v-model="isDateModal"
                  width="290px">
          <v-date-picker
              v-model="dateInternal"
              locale="ru-RU"
              color="#C09C5E"
              :min="minDate"
              :first-day-of-week="1"
              scrollable
              @input="onSetDate">
            <v-spacer></v-spacer>
            <v-btn text
                   @click="isDateModal=false">Отмена
            </v-btn>
            <!--						<v-btn text
                             color="primary"
                             @click="">Ок
                        </v-btn>-->
          </v-date-picker>
        </v-dialog>
      </v-col>
      <v-col cols="12" sm="4" v-if="!isSelf">
        <v-select v-model="form.TIME"
                  hide-details dense
                  label="Интервал"
                  placeholder="Интервал"
                  :items="times"/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import vp from '@/mixins/vp';
import {scrollTo} from '@/utils/utils';
import {date2Mysql} from '@/utils/date';
import {deliveryTypes} from '@/store/delivery';
import Config from "@/config";

export default {
  name: "basket-form-delivery-date",
  mixins: [vp],
  props: {
    form: {},
  },
  data() {
    return {
      isDateModal: false,
      dateInternal: null,
      DELIVERY_HOUR_LIMIT: Config.DELIVERY_HOUR_LIMIT,
      rulesDate: [
        v => v && !!v.match(/^([0-2][0-9]|3[0-1])\.(0[0-9]|1[0-2])\.20[2-3][0-9]/) || 'Введите дату (ДД.ММ.ГГГГ)'
      ],
      /*			times: [
              /!*{value: null,text: "выберите интервал"},*!/
              {text: "с 10:00 до 13:00"},
              {text: "с 13:00 до 16:00"},
              {text: "с 16:00 до 19:00"},
            ]*/
    }
  },
  computed: {
    times() {
      const intervals = this.$store.state.page?.BasketController.intervals;
      if (!intervals) return [];
      return intervals.map(interval => ({
        text: interval.NAME
      }));
    },
    deliveryType() {
      return deliveryTypes.find(el => el.id === this.form.deliveryTypeId);
    },
    isSelf() {
      return this.deliveryType?.isSelf;
    },
    settings() {
      return this.$store.state.page.BasketController.settings;
    },
    daysOrder() {
      const daysOrderSetting = this.settings.find(el => el.CODE === 'DAYS_ORDER');
      return Number(daysOrderSetting.VALUE) < 0 ? 0 : Number(daysOrderSetting.VALUE);
    },
    minDate() {
      const now = new Date();
      if (now.getHours() >= this.DELIVERY_HOUR_LIMIT && now.getHours() <= 23) {
        now.setDate(now.getDate() + 2 + this.daysOrder);
      } else {
        now.setDate(now.getDate() + 1 + this.daysOrder);
      }

      return date2Mysql(now);
    }
  },
  methods: {
    formatDate(date) {
      if (!date) return null;
      //return date2DDMMYYYY(mysql2Date(date));

      const [year, month, day] = date.split('-');
      return `${day}.${month}.${year}`;
    },
    onSetDate() {
      this.form.DATE_DELIVERY = this.formatDate(this.dateInternal);
      this.isDateModal = false;
    },
    /*onSelect() {
      //this.form.date = this.destination.date;

      setTimeout(() => {
        scrollTo("basket-form-delivery-date", -120);
      }, 300);

      this.$emit("select");
    },*/
  },
}
</script>

<style lang="scss">
.basket-form-delivery-date {
  .v-select {

  }
}
</style>