import { render, staticRenderFns } from "./select-city-popup.vue?vue&type=template&id=2d35aaba"
import script from "./select-city-popup.vue?vue&type=script&lang=js"
export * from "./select-city-popup.vue?vue&type=script&lang=js"
import style0 from "./select-city-popup.vue?vue&type=style&index=0&id=2d35aaba&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VCard,VDialog,VSelect})
