<template>
  <div class="page--padding layout-category"
  >
    <category-header :items="categoriesInCity"/>

    <!--<transition name="route" mode="out-in">-->
    <div class="layout-category__transition" :class="routeClass">
      <router-view :key="$route.path"/>
    </div>
    <!--</transition>-->
  </div>
</template>

<script>

import CategoryHeader from "@/views/Category/category-header";
import city from "@/mixins/city";

export default {
  name: "layout-category",
  components: {
    CategoryHeader,
  },
  mixins: [city],
  computed: {
    city() {
      return this.$store.state.common.city;
    },
    categories() {
      return this.$store.state.common.sections;
    },
    categoriesInCity() {
      return this.getAvailableInCityCategories(this.categories);
    },
    routeClass() {
      return this.$store.state.isLoading ? "loading" : null;
    },
    banner() {
      return this.$store.state.common.banner;
    }
  }
}
</script>

<style lang="scss">
.layout-category {

  &__transition {

    @include transition();
    opacity: 1;

    @include up($md) {
      padding-top: 32px;
    }
    @include up($lg) {
      padding-top: 16px;
    }

    &.loading {
      opacity: 0;
    }
  }

}
</style>